import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withContext } from './App';
import { withI18n } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import DeleteIcon from '@material-ui/icons/Delete';
import ShortTextIcon from '@material-ui/icons/ShortText';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import SlideshowIcon from '@material-ui/icons/Slideshow';
import PageviewIcon from '@material-ui/icons/Pageview';

const styles = theme => ({
	spacer: {
		flex: "1 1",
	},
	div1: {
		padding: theme.spacing.unit * 2,
	},
	div2: {
		color: theme.palette.secondary.contrastText,
	},
	filenameText: {
		paddingTop: 0,
		paddingBottom: 10,
	},
	deleteBtn: {
		margin: theme.spacing.unit,
	},
	downloadBtn: {
		margin: theme.spacing.unit,
	},
	smallPreviewImg: {
		cursor: "pointer",
		width: 'initial',
		minHeight: 100,
		maxHeight: 360,
		maxWidth: '80%',
		margin: 10,
		color: 'rgba(0, 0, 0, 0.87)',
		transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
		boxSizing: 'border-box',
		boxShadow: 'rgba(0, 0, 0, 0.12) 0 1px 6px, rgba(0, 0, 0, 0.12) 0 1px 4px',
		borderRadius: 2,
		opacity: 1,
		backgroundSize: "20px 20px, 20px 20px, 20px 20px, 20px 20px", 
		backgroundColor: "white", 
		backgroundPosition: "0 0, 0 10px, 10px -10px, -10px 0px", 
		backgroundImage: "linear-gradient(45deg,#c0c0c0 25%,transparent 25%), linear-gradient(-45deg,#c0c0c0 25%,transparent 25%), linear-gradient(45deg,transparent 75%,#c0c0c0 75%), linear-gradient(-45deg,transparent 75%,#c0c0c0 75%)",
	},
	imageContainer: {
		position: 'relative',
		textAlign: 'center',
	},
	action: {
		"&$disabledAction": {
		}
	},
	actionToolbar: {
		flex: "0 0 auto",
	},
	disabledAction: {
	},
});

class PreviewList extends Component {

	checkPreviewableType(type) {
		return type == "image/bmp"
			|| type == "application/vnd.oasis.opendocument.text"
			|| type == "application/vnd.oasis.opendocument.spreadsheet"
			|| type == "application/vnd.oasis.opendocument.presentation"
			|| type == "application/msword"
			|| type == "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
			|| type == "text/htm"
			|| type == "text/html"
			|| type == "image/jpg"
			|| type == "image/jpeg"
			|| type == "application/pdf"
			|| type == "image/png"
			|| type == "application/vnd.ms-powerpoint"
			|| type == "application/vnd.openxmlformats-officedocument.presentationml.presentation"
		//	|| type == "image/tiff" - No funciona
			|| type == "application/vnd.ms-excel"
			|| type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
			|| type == "application/epub+zip";
	}
	
	render() {
		const { fileObjects, handlePreview, handleView, handleDownload, handleRemove, handleCopyShortUrlToClipboard, classes } = this.props;

		return (
			<Grid container spacing={8}>
				{
					fileObjects != null 
							&&
					fileObjects.map((fileObject, i) => {
						return (
							<Grid item xs={12} sm={12} key={i} className={classes.imageContainer}>
								<div className={classes.div1}>
									<div className={classes.div2}>
										<Toolbar className={classes.toolbar2}>
											<Typography variant="h6" style={{ cursor: (this.checkPreviewableType(fileObject.type) ? "pointer" : "default") }} noWrap onClick={(this.checkPreviewableType(fileObject.type) ? handlePreview(i) : null)}>
												{fileObject.name + (fileObject.width != null && fileObject.height != null ? " (" + fileObject.width + "x" + fileObject.height + ")" : "")}
											</Typography>
											<div className={classes.spacer}/>
											<div className={classes.actionToolbar}>
												{
													fileObject.name.endsWith(".zip")
															&& this.props.browseZip
															&&
														<Tooltip title={this.props.t("view")} disableFocusListener>
															<span>
																<IconButton 
																		aria-label={this.props.t("view")}
																		classes={{
																			root: classes.action,
																			disabled: classes.disabledAction,
																		}}
																		onClick={handleView(i)}>
																	<PageviewIcon/>
																</IconButton>
															</span>
														</Tooltip>
												}
												{
													this.checkPreviewableType(fileObject.type)
															&&
														<Tooltip title={this.props.t("view")} disableFocusListener>
															<span>
																<IconButton 
																		aria-label={this.props.t("view")}
																		classes={{
																			root: classes.action,
																			disabled: classes.disabledAction,
																		}}
																		onClick={handlePreview(i)}>
																	<SlideshowIcon/>
																</IconButton>
															</span>
														</Tooltip>
												}
												<Tooltip title={this.props.t("download")} disableFocusListener>
													<span>
														<IconButton 
																aria-label={this.props.t("download")}
																classes={{
																	root: classes.action,
																	disabled: classes.disabledAction,
																}}
																onClick={handleDownload(i)}>
															<CloudDownloadIcon/>
														</IconButton>
													</span>
												</Tooltip>
												<Tooltip title={this.props.t("delete")} disableFocusListener>
													<span>
														<IconButton 
																disabled={this.props.disabled}
																aria-label={this.props.t("delete")}
																classes={{
																	root: classes.action,
																	disabled: classes.disabledAction,
																}}
																onClick={handleRemove(i)}>
															<DeleteIcon/>
														</IconButton>
													</span>
												</Tooltip>
												<Tooltip title={this.props.t("copyShortUrlToClipboard")} disableFocusListener>
													<span>
														<IconButton 
																aria-label={this.props.t("copyShortUrlToClipboard")}
																classes={{
																	root: classes.action,
																	disabled: classes.disabledAction,
																}}
																onClick={handleCopyShortUrlToClipboard(i)}>
															<ShortTextIcon/>
														</IconButton>
													</span>
												</Tooltip>
											</div>
										</Toolbar>
										{	
											fileObject.thumbnail != null
												&&
											<img className={classes.smallPreviewImg} role="presentation" src={fileObject.thumbnail} alt="" onClick={handlePreview(i)}/>
										}
									</div>
								</div>
							</Grid>
						);
					})
				}
			</Grid>
		);
	}
}

export default withStyles(styles)(withContext(withI18n()(PreviewList)));
